
import Vue from 'vue';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { checkPermission } from '@/api/utils';
import CategoryForm from '@/components/Event/CategoryForm.vue';
import CampaingForm from '@/components/Event/CampaingForm.vue';
import { PostRequest, GetRequest } from '@/util/http';
import { fetchClass } from '@/plugins/fetch';

const columnsCam = [
  {
    name: 'campaignName',
    required: true,
    label: 'Campaign Name',
    align: 'left',
    field: row => row.campaignName,
    sortable: true
  },
  {
    name: 'category', label: 'Campaign Category', field:  row => row.Cat?.name, sortable: true, align: 'left',
  },
  {
    name: 'numberOfStore', label: 'Number Of Store', field: row => row.filterStore.length, sortable: true, align: 'left',
  },
  {
    name: 'startDate', label: 'Start Date', field: row => row.campaignPeriod?.start, sortable: true, align: 'left',
  },
  {
    name: 'endDate', label: 'End Date', field: row => row.campaignPeriod?.end, sortable: true, align: 'left',
  },
  // {
  //   name: 'roas', label: 'ROAS', field: row => row.Badget, sortable: true, align: 'left',
  // },
  {
    name: 'budget', label: 'Budget', field: row => row.Badget, sortable: true, align: 'left',
  },
  {
    name: 'costPerTransaction', label: 'Cost Per Transaction', field: 'costPerTransaction', sortable: true, align: 'left',
  },
  {
    name: 'costPerTraffic', label: 'Cost Per Traffic', field: 'costPerTraffic', sortable: true, align: 'left',
  },
  { name: 'edit', label: 'Edit', align: 'center' },
  { name: 'delete', label: 'Delete', align: 'center' },
]

// const rowsCam = [
//   {
//     name: 'End Of The Year Event',
//     category: 'Campaing A',
//     numberOfStore: '3',
//     startDate: '2022/1/1',
//     endDate: '2022/3/30',
//     roas: '110%',
//     costPerTransaction: '3000',
//     costPerTraffic: '5000',
//   },
// ]

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Campaign Category',
    align: 'left',
    field: row => row.name,
    sortable: true
  },
  { name: 'description', align: 'left', label: 'Description', field: 'description', sortable: true },
  { name: 'edit', label: 'Edit', align: 'center' },
  { name: 'delete', label: 'Delete', align: 'center' },
]

const rows = [
  // {
  //   name: 'Campaing A',
  //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
  // },
  // {
  //   name: 'Campaing B',
  //   description: 'Sed est nibh, sodales a libero nec, rutrum mattis ex. Ut id pellentesque mauris. ',
  // },
  // {
  //   name: 'Campaing C',
  //   description: 'Aliquam sit amet elit sed felis dignissim bibendum. Donec nec purus erat.',
  // }
]

export default {
  // components: { BoxTarget },
  data () {
    return {
      columns,
      rows,
      columnsCam,
      rowsCam: [],
      deleteConfirmation: false,
      deleteConfirmationCamp: false,
      campaignRowId: 0,
      campaignCatRowId: 0,
      tab: 'management'
    };
  },
  async mounted() {
    this.loadCampaignCategories();
    this.loadCampaigns();
   },
  methods: {
    viewAllowed(name) {
      return checkPermission(name);
    },
    refreshPage () {
      this.lastTimeStamp = Date.now();
    },

    loadCampaigns()
    {
    GetRequest('http://localhost:3000/campaigns',{},{ headers: null })
      .then(response => response)
      .then(data => {
        this.rowsCam = data;
      })
    },
    loadCampaignCategories()
    {
    GetRequest('http://localhost:3000/categories',{},{ headers: null })
      .then(response => response)
      .then(data => {
        this.rows = data
      })
    },
    showDialog(row) {
      this.$q.dialog({
        component: CategoryForm,
        category: row,
      }).onOk(() => {
        GetRequest('http://localhost:3000/categories',{},{ headers: null })
      .then(response => response)
      .then(data => {
        this.rows = data
      })
      }).onCancel(() => {
      }).onDismiss(() => {
      })
    },
    showdeleteConfirmation  (row) {
      this.campaignRowId = row.id;
      this.deleteConfirmation = true;
          //   // remove category from the list
    },
    showdeleteConfirmationCamp (row){
      this.campaignCatRowId = row.id;
      this.deleteConfirmationCamp = true;
    },
    onDeleteCamp(){
    this.rowsCam =  this.rowsCam.filter(c => c.id !== this.campaignCatRowId)

    fetchClass(
      'http://localhost:3000/campaigns/'+this.campaignCatRowId,
        {
          method: 'DELETE',
        },{ headers: null }
      );

    this.deleteConfirmationCamp = false;
    },
    onDelete() {
      this.rows =  this.rows.filter(c => c.id !== this.campaignRowId)

      fetchClass(
        'http://localhost:3000/categories/'+this.campaignRowId,
          {
            method: 'DELETE',
          },{ headers: null }
        );
      this.deleteConfirmation = false;

      // this.$q.notify({
      //   message: this.$t('SettingsManagement.successfully_deleted'),
      //   type: 'positive',
      //   position: 'top',
      //   timeout: 3000
      // });
      // await this.fetchStores();
    },
    showDialogCam (row) {
      this.$q.dialog({
        component: CampaingForm,
        editCampaign: row,
      }).
      onOk(() => {
        GetRequest('http://localhost:3000/campaigns',{},{ headers: null })
      .then(response => response)
      .then(data => {
        this.rowsCam = data;
      })
      }).onCancel(() => {
      }).onDismiss(() => {
       // console.log('I am triggered on both OK and Cancel')
      })
    }
  }
};
